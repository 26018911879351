<template>
  <div class="unread-message-wrapper">
    <el-card>
      <SearchBar slot="header" :hasMessage="true" @handleSearchMessage="handleSearchMessage" @handleAllMessage="handleAllMessage" />
      <MessageTable :messageStatus="0" ref="MessageTable" :message="message" :height="tHeight" @handleConfirm="handleConfirm" />
      <TabbedBrowsing :page="page" :size="size" :total="total" @handleCurrentChange="handleCurrentChange" />
    </el-card>
  </div>
</template>

<script>
import SearchBar from '../components/SearchBar.vue';
import MessageTable from '../components/MessageTable.vue';
import TabbedBrowsing from '../components/TabbedBrowsing.vue';
import mixin from '../mixin.js';
import statusInclude from '@/utils/statusCode';
export default {
  components: {
    SearchBar,
    MessageTable,
    TabbedBrowsing,
  },
  mixins: [mixin],
  data() {
    return {
      page: 1,
      size: 13,
      total: 100,
      message: [],
      locating: {
        msgType: 0,
        searchContent: '',
        searchType: '',
      },
    };
  },
  mounted() {
    this.getSourceSize(200).then((size) => {
      this.size = size;
      this.getMessageList(this.page, this.size, 0, '', '');
    });
  },
  methods: {
    //按名称搜索
    handleSearchMessage(info) {
      const { msgType, searchContent, searchType } = info;
      this.locating = info;
      this.getMessageList(this.page, this.size, msgType, searchContent, searchType);
    },
    //监听单个点击数据
    handleConfirm(msgId) {
      this.submitMessageOptions([msgId]);
    },

    //提交选中的数据
    async handleAllMessage() {
      const res = this.$refs.MessageTable.submitOptions(); //获取已经选中的数据
      const msgIds = res.reduce((per, cur) => per.concat(cur.msgId), []);
      this.submitMessageOptions(msgIds);
    },
    handleCurrentChange(page) {
      this.page = page;
      const { msgType, searchContent, searchType } = this.locating;
      this.getMessageList(this.page, this.size, msgType, searchContent, searchType);
    },
    async getMessageList(page, size, msgType, searchContent = '', searchType = '', status = 0) {
      this.getNotictMsgList(page, size, msgType, searchContent, searchType, status).then((response) => {
        const { code, reason, data } = response;
        if (!statusInclude(code)) return this.$message({ type: 'warning', message: reason });
        const { records, total } = data;
        this.message = records;
        this.total = total;
      });
    },
    //提交已读消息
    async submitMessageOptions(msgIds) {
      if (!msgIds.length) return this.$message({ type: 'warning', message: '请选择消息' });
      const { code, reason } = await this.noticeMsgRead(msgIds);
      if (!statusInclude(code)) return this.$message.error(`请求错误：${reason}`);
      this.$message({ type: 'success', message: '请求成功' });
      const { msgType, searchContent, searchType } = this.locating;
      this.getMessageList(this.page, this.size, msgType, searchContent, searchType);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
  position: relative;
  height: calc(100vh - #{$offset});
}
</style>
